import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import validate from 'jquery-validation';


$(document).ready(function () {
    $.validator.addMethod("investAmountStep", function (value, element) {
        var amt = $(element).val().toLowerCase().replace(/[^0-9]+/g, "");
        amt = parseInt(amt);
        return amt % 100000 === 0;
    }, "Amount must be multiplied by 1 Lac");
    $.validator.addMethod("nameValidate", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^([a-zA-Z .])+$/.test(value);
    }, "Only Space and dot(.) allowed");
    $.validator.addMethod("alphaNumericValidateDotSpace", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^([a-zA-Z0-9 .])+$/.test(value);
    }, "Only Space and dot(.) allowed");
    $.validator.addMethod("entityNameValidate", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^([a-zA-Z0-9 \-.()])+$/.test(value);
    }, "Invalid Character Entered");
    $.validator.addMethod("alphaNumericValidate", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^[\w]+$/.test(value);
    }, "Invalid Character Entered");
    $.validator.addMethod("decimalValidate", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^[0-9]+(\.[0-9]{1,2})?$/.test(value);
    }, "Not a decimal number up to 2 point");
    $.validator.addMethod("adhaarNumberValidate", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^[0-9]{12}$/.test(value);
    }, "Invalid Aadhaar Number");
    $.validator.addMethod("pannumber", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);
    }, "Invalid PAN Number");
    $.validator.addMethod("voterIdNumber", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^[\w]+$/.test(value);
        //return /^([A-Z]){3}([0-9]){7}$/.test(value);
    }, "Invalid Voter Id Number");
    $.validator.addMethod("addressFieldValidation", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^([a-zA-Z0-9 \-.#,/&])+$/.test(value);
    }, "Only . / - # , Special character allowed");
    $.validator.addMethod("equalTo", function (value, element) {
        var depend = $(element).data("equal");
        // console.log(depend);
        return value === $(depend).val();
    }, function (params, element) {
        var message = $(element).data("message");
        return message;
    });
    $.validator.addMethod("proofValidation", function (value, element) {
        if (value === "") {
            return true;
        }
        var elementName = $(element).attr('name');
        var selectedOptName = "";
        if (elementName.indexOf('[address_proof_number]') !== -1) {
            selectedOptName = elementName.replace('[address_proof_number]', '[address_proof_type]');
        } else {
            selectedOptName = elementName.replace('[identification_proof_number]', '[identification_proof_type]');
        }
        var selectedType = $("form:visible input[name='" + selectedOptName + "']").val();
        if (!selectedType || selectedType === "") {
            return true;
        }

        var selOpt = $("form:visible .dynamic-select[data-field='" + selectedOptName + "'][data-value='" + selectedType + "']");
        // console.log('selOpt');
        // console.log(selOpt.length);
        if (!selOpt.length) {
            return true;
        }
        var regEx = selOpt.data('regex');
        if (!regEx || regEx === "") {
            return true;
        }
//        console.log(selectedOptName, selectedType, regEx);
        if (selectedType === 'aadhaar_card') {
            $(element).attr('minlength', 12);
            $(element).attr('maxlength', 12);
        } else if (selectedType === 'voter_id_card') {
            $(element).attr('minlength', 10);
            $(element).attr('maxlength', 10);
        } else {
            $(element).removeAttr('minlength');
            $(element).removeAttr('maxlength');
        }
        var patt = new RegExp(regEx);
        return patt.test(value);
    }, "Invalid Proof Number");
    $.validator.addMethod("alphaNumericWithSpaceValidate", function (value, element) {
        if (value === "") {
            return true;
        }
        return /^[\w ]+$/.test(value);
    }, "Invalid Character Entered");
    $.validator.addMethod("tinOrPassport", function (value, element) {
        if ($(element).attr('name') !== 'identity_details[passport_number]') {
            if (value === "" && $("input[name='identity_details[passport_number]']").val() === "") {
                $('.tin-section').find("input[name='identity_details[passport_number]']").removeAttr('required');
                return false;
            }
            return true;
        } else {
            return true;
        }
    }, "Tin or Passport is required");

    $.validator.addMethod("investAmountMinMax", function (value, element) {
        var amt = $(element).val().toLowerCase().replace(/[^0-9]+/g, "");
        amt = parseInt(amt);
        var minAmount = parseInt($(element).data('minamount'));
        var maxAmount = parseInt($(element).data('maxamount'));
        return (amt >= minAmount && amt <= maxAmount);
    }, function (params, element) {
        var minAmount = parseInt($(element).data('minamount'));
        var maxAmount = parseInt($(element).data('maxamount'));
        var formattedMinAmount = globalJS.numberToCurrency(minAmount);
        var formattedMaxAmount = globalJS.numberToCurrency(maxAmount);
        var amt = $(element).val().toLowerCase().replace(/[^0-9]+/g, "");
        amt = parseInt(amt);
        if (amt < minAmount) {
            return 'Minimum investment amount should be ' + formattedMinAmount;
        } else if (amt > maxAmount) {
            return 'Maximum investment amount should be ' + formattedMaxAmount;
        } else {
            return 'Amount shoud be between ' + minAmount + ' and ' + maxAmount
        }
    });

    $.validator.addMethod("lettersonly", function (value, element) {
        return this.optional(element) || /^[a-z]+$/i.test(value);
    }, "Letters only please");
    $.validator.addMethod("numbersonly", function (value, element) {
        return this.optional(element) || /^[0-9]+$/i.test(value);
    }, "Numbers only please");
    $.validator.setDefaults({
        highlight: function (element) {

            if (!element.hasAttribute('required')) {
                return;
            }

            handleErrorSuccess(element, false);

            if ($(element).data('errorEle')) {
                $($(element).data('errorEle')).removeClass('hide');
            }
            // add a class "has_error" to the element
            $(element).addClass('error');
        },
        unhighlight: function (element) {

            if (!element.hasAttribute('required')) {
                return;
            }

            if ($(element).parents('.pin-input-wrapper').length) {
                return;
            }

            handleErrorSuccess(element, true);

            if ($(element).data('errorEle')) {
                $($(element).data('errorEle')).addClass('hide');
            }
            // remove the class "has_error" from the element
            $(element).removeClass('error');
        },
        errorPlacement: function (error, element) {

            if (element.hasClass('disable-validation')) {
                return;
            }

            if (element.hasClass('disable-validation-check')) {
                return;
            }

            if ($(element).siblings('.strong-password-validation-block').length > 0) {
                return;
            }
            let errorImage = "";
            if (!element.hasClass('no-cross-icon')) {
                // errorImage = `<div class="input-img">
                //                 <img src="/images/error.svg">
                //             </div>`;
            }
            let insertAfterSet = false;
            let insertAfterElem = element;
            if (element.data('insert_after') && $(element.data('insert_after')).length) {
                insertAfterSet = true;
                insertAfterElem = $(element.data('insert_after'));
            }
            var customError = $(`<div class="error-validation-container">
            ${errorImage}
            </div>
            `);

            if(!insertAfterSet) {
                insertAfterElem = element.parents().closest('.form-group');
                // console.log(insertAfterElem);
            }


            error.addClass('input-error-msg');
            error.appendTo(customError);
            customError.appendTo(insertAfterElem);
        }
    });

    function handleErrorSuccess(element, valid) {
        var successValidationMessage = '';
        if (!$(element).hasClass('disable-validation') && $(element).hasClass('show-success-msg')) {
            successValidationMessage = '<div class="input-img">\n' +
                '<img src="/images/success.svg">\n' +
                '</div>';
        }
        var successValidationContainer = $(['<div class="success-validation-container"></div>'].join(""));
        var successValidationContainerInDom = $(element).siblings('.success-validation-container');
        var errorValidationContainerInDom = $(element).siblings('.error-validation-container');

        (errorValidationContainerInDom) ? errorValidationContainerInDom.remove() : null;
        (successValidationContainerInDom) ? successValidationContainerInDom.remove() : null;

        if (valid && !$(element).hasClass('ignore-success')) {
            $(successValidationContainer).html(successValidationMessage);
            $(element).after(successValidationContainer);
        } else {
            if ($(element).attr('type') === 'password') {
                errorValidationContainerInDom.find('.input-img').remove();
                if ($(element).siblings('.strong-password-validation-block').length > 0) {
                    errorValidationContainerInDom.remove();
                }
            }
        }
    }

    $.validator.addMethod("phoneShouldNotWith0", function (phone_number, element) {
        return this.optional(element) || !phone_number.startsWith('0');
    }, "Phone number must not start with 0");

    $.validator.addMethod("phoneIncludingIndia", function (phone_number, element) {
        var phoneIncludingIndiaSatisfied = false;

        if ($(element).siblings('.iti__flag-container').length) {
            let parent = $(element).siblings('.iti__flag-container');
            let selectedCountryCode = parent.find('.iti__selected-dial-code').text();

            if (selectedCountryCode !== '+91') {
                phoneIncludingIndiaSatisfied = true;
            } else if (selectedCountryCode === '+91' && phone_number.length === 10) {
                phoneIncludingIndiaSatisfied = true;
            }
        }

        return phoneIncludingIndiaSatisfied;
    }, "Phone number must be 10 digits");

    $.validator.addMethod("customYield", function (customYield, element) {
        var customYieldValid = true;

        if (!customYield || customYield < 0) {
            customYieldValid = false;
        }
        return customYieldValid;
    }, "Yield must be greater than 0");

    $.validator.addMethod("customYieldMin", function (customYield, element) {
        let lowestYield = $(element).attr('min');
        var customYieldValid = true;

        if (!customYield || parseFloat(customYield) <= lowestYield) {
            customYieldValid = false;
        }
        return customYieldValid;
    }, function (params, element) {
        return 'Please enter the yield greater than ' + $(element).attr('min');
    });

    $.validator.addMethod("estimatedInvestmentAmount", function (estimatedAmount, element) {
        var estimatedInvestmentAmountValid = true;
        estimatedAmount = estimatedAmount.replace(/,/g, '');
        if (estimatedAmount < 0) {
            estimatedInvestmentAmountValid = false;
        }
        return estimatedInvestmentAmountValid;
    }, "Amount must be greater than 0");

    $.validator.addMethod("passwordPolicyCheck", function (passwordVal, element) {
        let passwordValid = true;

        if (!(passwordVal.match('(?=.*[a-z])') && passwordVal.match('(?=.*[A-Z])') && passwordVal.match('(?=.*[\\W_])') && passwordVal.match('(?=.*\\d)'))) {
            passwordValid = false;
        }
        return this.optional(element) || passwordValid;
    }, "Please enter a strong password");

    $.validator.addMethod("emailCustomValidator", function (passwordVal, element) {
        var emailValid = true;
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(passwordVal)) {
            emailValid = false;
        }

        return this.optional(element) || emailValid;
    }, "Please enter a valid email address");

    $.validator.addMethod("emailVerifier", function (value, element) {
        return !!(this.optional(element) || $(element).attr('data-verified'));
    }, 'Click on "Verify email address" to verify your email');

    $.validator.addMethod("phoneVerifier", function (value, element) {
        return !!(this.optional(element) || $(element).attr('data-verified'));
    }, 'Click on "Verify phone number" to verify your phone');

    $.validator.addClassRules({
        login_password: {
            required: true
        },
        password: {
            required: true,
            minlength: 8,
            maxlength: 15,
            passwordPolicyCheck: true
        },
        current_password: {
            required: true
        },
        password_confirmation: {
            required: true,
            equalTo: '.password'
        },
        email: {
            emailCustomValidator: true
        },
        full_name: {
            required: true,
            maxlength: 40,
            lettersonly: true
        },
        first_name: {
            required: true,
            maxlength: 20,
            lettersonly: true
        },
        last_name: {
            required: true,
            maxlength: 20,
            lettersonly: true
        },
        phone: {
            number: true,
            required: true,
            phoneShouldNotWith0: true,
            min: 1,
            maxlength: 15,
            minlength: 5
        },
        phoneIncludingIndia: {
            number: true,
            required: true,
            phoneShouldNotWith0: true,
            phoneIncludingIndia: true,
            min: 1,
            maxlength: 15,
            minlength: 5
        },
        country_code: {
            required: true,
            number: true
        },
        otp: {
            required: true,
            minlength: 8,
            maxlength: 16,
            number: true
        },
        investAmount: {
            investAmountStep: true,
            investAmountMinMax: true,
        },
        activation_code: {
            required: true,
        },
        customYield: {
            customYield: true
        },

        customYieldMin: {
            customYieldMin: true
        },
        estimated_investment_amount: {
            estimatedInvestmentAmount: true
        },
        nameValidate: {
            nameValidate: true
        },
        onlyNumber: {
            number: true
        },
        entityNameValidate: {
            entityNameValidate: true
        },
        alphaNumericValidate: {
            alphaNumericValidate: true
        },
        alphaNumericWithSpaceValidate: {
            alphaNumericWithSpaceValidate: true
        },
        decimalValidate: {
            decimalValidate: true
        },
        proofValidation: {
            proofValidation: true
        },
        adhaarNumberValidate: {
            adhaarNumberValidate: true
        },
        pannumber: {
            pannumber: true
        },
        voterIdNumber: {
            voterIdNumber: true
        },
        addressField: {
            addressFieldValidation: true
        },
        equalTo: {
            equalTo: true
        },
    });
});
